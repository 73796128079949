/* eslint-disable class-methods-use-this */
import { openDB } from 'idb'
import ImgixClient from '@imgix/js-core'
import arrayShuffle from 'array-shuffle'
import React from 'react'
import { ApolloQueryResult, gql, throwServerError } from '@apollo/client'
import mixpanel from 'mixpanel-browser'
import EventEmitter from 'events'
import Hls from 'hls.js'
import client from './apollo'
import { PlaylistEntityResponse } from '../generated/graphql'
import getCloudfrontURL from './cloudfront'

const imgixClient = new ImgixClient({
  domain: 'antless-media.imgix.net',
})

interface Track {
  title: string
  artist: string
  id: number
  url: string
}
export interface TrackStatus {
  track: Track | null
  seek: number
  duration: number
  destinationId: number | null
  isPlaying: boolean
  playlist: PlaylistEntityResponse | null
}

class Player extends EventEmitter {
  tracks: Track[] = []

  playlistId: number = 0

  playlist: PlaylistEntityResponse | null = null

  destinationId: number | null = null

  currentTrack: Track | null = null

  db: any

  constructor() {
    openDB('playedTracks', 1, {
      upgrade(db) {
        db.createObjectStore('tracks')
      },
    }).then((db) => {
      this.db = db
    })
    super()

    // track the listen time
    setInterval(() => {
      try {
        const status = this.getTrackStatus()
        if (!status.isPlaying) return
        // if (!status.track || !status.track.title) return
        mixpanel.track('minute-listened', {
          ...status,
        })
      } catch (e) {
        console.error(e)
      }
    }, 60000)
  }

  createMediaSession = () => {
    if ('mediaSession' in navigator) {
      const audio = document.getElementById('audioTag') as HTMLAudioElement
      navigator.mediaSession.setActionHandler('pause', () => {
        if (this.isPlaying()) {
          this.emit('pause')
          audio.pause()
        } else {
          this.emit('play')
          audio.play()
        }
      })
      navigator.mediaSession.setActionHandler('play', () => {
        this.emit('play')
        audio.play()
      })
      navigator.mediaSession.setActionHandler('nexttrack', async () => {
        this.play()
      })
      navigator.mediaSession.setActionHandler('previoustrack', async () => {
        this.play()
      })
    }
    const cover = this.playlist?.data?.attributes?.cover.data?.attributes?.url
    if (cover) {
      const relativeSrc = cover.replace(
        'https://antless-strapi-media-prod.s3.eu-central-1.amazonaws.com/',
        ''
      )
      const extension = relativeSrc.split('.').pop()
      navigator.mediaSession.metadata = new MediaMetadata({
        title: this.playlist?.data?.attributes?.title || '',
        artist: 'Antless',
        artwork: [
          {
            src: imgixClient.buildURL(relativeSrc, { w: 96, h: 96, fm: 'jpg', fit: 'crop' }),
            sizes: '96x96',
            type: `image/jpeg`,
          },
          {
            src: imgixClient.buildURL(relativeSrc, { w: 128, h: 128, fm: 'jpg', fit: 'crop' }),
            sizes: '128x128',
            type: `image/jpeg`,
          },
          {
            src: imgixClient.buildURL(relativeSrc, { w: 192, h: 192, fm: 'jpg', fit: 'crop' }),
            sizes: '192x192',
            type: `image/jpeg`,
          },
          {
            src: imgixClient.buildURL(relativeSrc, { w: 256, h: 256, fm: 'jpg', fit: 'crop' }),
            sizes: '256x256',
            type: `image/jpeg`,
          },
          {
            src: imgixClient.buildURL(relativeSrc, { w: 384, h: 384, fm: 'jpg', fit: 'crop' }),
            sizes: '384x384',
            type: `image/jpeg`,
          },
          {
            src: imgixClient.buildURL(relativeSrc, { w: 512, h: 512, fm: 'jpg', fit: 'crop' }),
            sizes: '512x512',
            type: `image/jpeg`,
          },
        ],
      })
      console.log('SET METADATA', navigator.mediaSession.metadata)
    }
  }

  play(autoplay = true) {
    if ((window as any).hls) {
      ;(window as any).hls.destroy()
    }
    const seed = Math.floor(Math.random() * 1000000)
    const streamURL = `${
      process.env.NODE_ENV === 'development' ? 'https://app.antless.studio' : ''
    }/api/stream-vod/${this.playlistId}/${seed}`
    // const streamURL = `https://d3utvtro6055mu.cloudfront.net/hls/afterwork1mixedbyskywlkr-b3a4e6f4conv.m3u8`
    const hls = new Hls({
      manifestLoadingMaxRetry: 10, // Maximum number of retries to load the manifest
      levelLoadingMaxRetry: 10, // Maximum number of retries to load a playlist
      fragLoadingMaxRetry: 10, // Maximum numbe
      startPosition: 0,
      liveSyncDurationCount: 20,
      maxLiveSyncPlaybackRate: 1,
    })
    const audio = document.getElementById('audioTag') as HTMLAudioElement
    hls.on(Hls.Events.ERROR, (_, data) => {
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            console.log('fatal network error encountered, try to recover')
            hls.startLoad()
            break
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.log('fatal media error encountered, try to recover')
            hls.recoverMediaError()
            break
          default:
            console.log('non recoverable error encountered')
            break
        }
      }
    })
    if (Hls.isSupported()) {
      console.log('Using HLS player')
      hls.loadSource(streamURL)
      hls.attachMedia(audio)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (autoplay) audio.play()
        this.emit('play')
      })
    } else if (audio.canPlayType('application/vnd.apple.mpegurl')) {
      console.log('Using native audio tag')
      audio.currentTime = 15
      audio.playbackRate = 1
      audio.src = streamURL
      audio.addEventListener('loadedmetadata', () => {
        if (autoplay) audio.play()
        this.emit('play')
        audio.currentTime = 15
        audio.playbackRate = 1
      })
    }
    if (audio) {
      audio.title = this.playlist?.data?.attributes?.title || ''
      this.createMediaSession()
    }
    audio.addEventListener('pause', () => {
      this.emit('pause')
    })
    audio.addEventListener('play', () => {
      this.emit('play')
    })
    ;(window as any).hls = hls
  }

  async setPlaylist(playlistId: number, destinationId: number, autoPlay: boolean = false) {
    if (this.playlistId === playlistId) {
      // if (autoPlay) {
      //   if (this.isPlaying()) return
      // }
      return
    }
    const res: ApolloQueryResult<{ playlist: PlaylistEntityResponse }> = await client.query({
      query: gql`
        query PLAYLIST_BY_ID($id: ID) {
          playlist(id: $id) {
            data {
              id
              attributes {
                title
                cover {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        id: playlistId,
      },
    })
    this.playlistId = playlistId
    this.destinationId = destinationId
    const { playlist } = res.data
    this.playlist = playlist
    this.emit('playlist-changed')

    // this.tracks = tracks as any
    this.play(this.isPlaying())
    mixpanel.track('Playlist Selected', {
      name: playlist?.data?.attributes?.title,
      destinationId: this.destinationId,
    })
  }

  getActivePlaylist() {
    return this.playlist
  }

  async start() {
    if ((window as any).expired) {
      console.debug('subscription expired')
      return
    }
    // const track = await this.getNextTrack()
    if ((window as any).hls) {
      const audio = document.getElementById('audioTag') as HTMLAudioElement
      if (audio) audio.play()
    } else {
      this.play()
    }
    // mixpanel.track('Track Started', {
    //   trackName: track.title,
    //   destinationId: this.destinationId,
    // })
  }

  pause() {
    const audio = document.getElementById('audioTag') as HTMLAudioElement
    if (audio) audio.pause()
    this.emit('pause')
  }

  resume() {
    if ((window as any).hls) {
      const audio = document.getElementById('audioTag') as HTMLAudioElement
      if (audio) audio.play()
      this.emit('play')
    } else {
      this.play()
    }
  }

  // unload() {
  //   if (this.getHowlerInstance()) this.getHowlerInstance().unload()
  // }

  getCurrentTrack(): Track | null {
    return this.currentTrack
  }

  getCurrentSeek(): number {
    return 0
  }

  getDuration(): number {
    return 0
  }

  skipTrack() {
    mixpanel.track('track skipped', this.getTrackStatus())
    this.play()
    console.log('skipping track')
  }

  getTrackStatus(): TrackStatus {
    return {
      track: this.getCurrentTrack(),
      seek: this.getCurrentSeek(),
      duration: this.getDuration(),
      isPlaying: this.isPlaying(),
      destinationId: this.destinationId,
      playlist: this.playlist,
    }
  }

  isPlaying() {
    const audio = document.getElementById('audioTag') as HTMLAudioElement
    console.log('isPlaying', audio?.paused)
    if (audio) return !audio.paused
    return false
  }

  /**
   * Set the volume of the player
   * @param v number from 0 to 1
   */
  setVolume(v: number) {
    const audio = document.getElementById('audioTag') as HTMLAudioElement
    if (audio) audio.volume = v
  }

  getVolume() {
    const audio = document.getElementById('audioTag') as HTMLAudioElement
    if (audio) return audio.volume
    return 1
  }

  async getNextTrack(): Promise<Track> {
    const playedTracks = await this.db.getAllKeys('tracks')
    const nextTrack = arrayShuffle(
      this.tracks.filter((track) => !playedTracks.includes(Number(track.id)))
    )[0]
    if (!nextTrack) {
      // all tracks have been played, clear the playedTracks
      await this.db.clear('tracks')
      return arrayShuffle(this.tracks)[0]
    }
    return nextTrack
  }

  isLoading() {
    return false
  }
}

export default Player

export const PlayerContext = React.createContext<Player>({} as Player)
