import { API, Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'
import { createStripeCustomerPortalSession } from '../../graphql/mutations'
import Button from '../../components/generic/Button'
import GlobalContextContext from '../../utils/globalContext'
import { CreateStripeCustomerPortalSessionMutation } from '../../API'

const Wrapper = styled.div`
  padding: 4rem;
  & > div {
    background: #fff;
    border: 1px solid black;
    border-radius: 0.6rem;
    padding: 2.5rem;
    h1 {
      font-family: ${(props) => props.theme.fonts.primary};
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
`

const ButtonGroup = styled.div`
  & > * {
    display: block;
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0rem;
    }
  }
  & > a {
    text-decoration: none;
    color: black;
    margin-bottom: 4rem;
    display: block;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`

const Settings = () => {
  const { signOut, user } = React.useContext(GlobalContextContext)
  const navigate = useNavigate()

  const openPaygateSession = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const result = (await API.graphql({
      query: createStripeCustomerPortalSession,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      authToken: token,
    })) as { data: CreateStripeCustomerPortalSessionMutation }
    window.location.href = result.data.createStripeCustomerPortalSession as string
  }

  return (
    <Wrapper>
      <div>
        <h1>Settings</h1>
        <ButtonGroup>
          <Link to="/">go back</Link>
          <Button
            onClick={() => {
              signOut()
              setTimeout(() => {
                window.location.href = '/'
              }, 1000)
            }}
          >
            Logout
          </Button>
          <Button onClick={openPaygateSession}>Subscription Settings</Button>
          <Button onClick={() => navigate('/tutorial')}>Tutorial</Button>
        </ButtonGroup>
      </div>
    </Wrapper>
  )
}

export default Settings
