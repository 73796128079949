import React, { useContext, useEffect, useMemo, useState } from 'react'
import Fuse from 'fuse.js'
import styled from 'styled-components'
import { API, Auth } from 'aws-amplify'
import { ApolloQueryResult } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import client from '../../utils/apollo'
import PlaylistCard from '../playlistCard/playlistCard'
import { listFavorites } from '../../graphql/queries'
import { Favorites, ListFavoritesQuery } from '../../API'
import {
  DestinationEntityResponseCollection,
  PlaylistEntity,
  PlaylistEntityResponseCollection,
} from '../../generated/graphql'
import { PLAYLISTS_QUERY } from '../favoritesList/favoriteList.query'
import { DESTINATION_QUERY } from '../search/search.query'

const Wrapper = styled('div')<{ show: boolean }>`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: scroll;
  transform: translateY(${(props) => (props.show ? '0' : '-100%')});
  transition: transform 0.2s ease-in-out;
  background: white;

  z-index: 20;
  padding: 10rem 2rem 0;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Input = styled.input`
  width: 100%;
  font-size: 2.4rem;
  font-weight: 300;
  border: none;
  outline: none;
  shadow: none;
  background: transparent;
  letter-spacing: 6%;
  margin-bottom: 2.4rem;
  ::placeholder {
    opacity: 0.2;
  }
`

const ResultGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 50%);
  column-gap: 1.2rem;
  margin-bottom: 2.4rem;
`

const DestinationHeadline = styled.span`
  font-size: 2.4rem;
  font-family: ${(props) => props.theme.fonts.primary};
  display: block;
  margin-bottom: 1.5rem;
`
interface MergedPlaylistFavorites {
  playlist: PlaylistEntity
  favorite: Favorites
}

const FavoritesList = ({ show, onSelect }: { show: boolean; onSelect: () => void }) => {
  const [favorites, setFavorites] = useState<MergedPlaylistFavorites[]>()
  const navigate = useNavigate()

  const getFavorite = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const result = (await API.graphql({
      query: listFavorites,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      authToken: token,
    })) as { data: ListFavoritesQuery }
    if (!result.data.listFavorites) return
    // eslint-disable-next-line no-underscore-dangle
    const activeItems = result.data.listFavorites.items.filter((item) => !item?._deleted)
    const ids = activeItems.map((item: any) => item.playlistId)
    const res: ApolloQueryResult<{ playlists: PlaylistEntityResponseCollection }> =
      await client.query({
        query: PLAYLISTS_QUERY,
        variables: {
          ids,
        },
      })
    const merged = res.data.playlists.data
      .map((item) => ({
        playlist: item,
        favorite: activeItems.find(
          (activeItem) => Number(activeItem?.playlistId) === Number(item.id)
        ),
      }))
      .filter((i) => !!i.favorite)
    setFavorites(merged as MergedPlaylistFavorites[])
  }

  useEffect(() => {
    getFavorite()
  }, [show])

  const onPlaylistClick = async (id: string) => {
    const res = await client.query<{ destinations: DestinationEntityResponseCollection }>({
      query: DESTINATION_QUERY,
      variables: { id },
    })
    if (!res || !res.data.destinations || !res.data.destinations.data[0]) return
    onSelect()
    navigate(`/destination/${res.data.destinations.data[0].id}/${id}`)
  }

  if (!favorites || !favorites.length) return null

  return (
    <Wrapper show={show}>
      <DestinationHeadline>Favorites</DestinationHeadline>
      <ResultGrid>
        {favorites.map((d) => (
          <PlaylistCard key={d.playlist.id} p={d.playlist} onClick={onPlaylistClick} />
        ))}
      </ResultGrid>
    </Wrapper>
  )
}

export default FavoritesList
