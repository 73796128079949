import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CognitoUser } from '@aws-amplify/auth'
import mixpanel from 'mixpanel-browser'
import styled from 'styled-components'
import Logo from '../../assets/logo'
import SearchIcon from '../../assets/Icons/Search'
import Settings from '../../assets/Icons/Settings'
// import { PlayerContext } from '../../utils/player'
// import { PlaylistEntity, PlaylistEntityResponse } from '../../generated/graphql'
import Search from '../search/search'
import GlobalContextContext from '../../utils/globalContext'
import Bookmark from '../../assets/Icons/Bookmark'
import Favorites from '../favorites/favorites'
import Close from '../../assets/Icons/Close'

const HeaderWrapper = styled.header`
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 7rem;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  z-index: 999;
  background-color: white;
  padding: 0 0.9rem;
  padding-left: 2rem;
  svg {
    width: 150px;
    height: auto;
  }
`

const Action = styled.div`
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  svg {
    color: transparent;
    width: 4rem;
    height: 4rem;
  }
`

const SettingsContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  & > div,
  & > a {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

const ActivePlaylistTitle = styled(Link)`
  font-size: 2.4rem;
  letter-spacing: 14%;
  font-family: ${(props) => props.theme.fonts.primary};
  font-weight: 300;
  margin-left: 5rem;
  display: none;
  text-decoration: none;
  color: black;
  @media ${(props) => props.theme.breakpoints.md} {
    display: block;
  }
`

const Header = ({
  user,
  onClose,
}: {
  user: CognitoUser | undefined
  onClose: (() => void) | undefined
}) => {
  // const player = useContext(PlayerContext)
  const [showSearch, setShowSearch] = useState(false)
  const [showFavorites, setShowFavorites] = useState(false)
  const { playerStatus } = useContext(GlobalContextContext)
  // const [activePlaylist, setActivePlaylist] = useState<PlaylistEntityResponse | null>(null)

  const handleLogoClick = (e: React.MouseEvent) => {
    if (showSearch) {
      setShowSearch(false)
    } else {
      e.preventDefault()
    }
  }

  useEffect(() => {
    // console.log('USER', user?.getUsername())
    mixpanel.identify(user?.getUsername())
    user?.getUserAttributes((e, d) => {
      const data: { [key: string]: string } = {}
      if (!d) return
      d.forEach((attr) => {
        if (attr.Name === 'email') {
          return
        }
        data[attr.Name] = attr.Value
      })
      mixpanel.people.set(data)
    })
    // player?.on('playlist-changed', () => {
    //   if (!player.getActivePlaylist()) return
    //   setActivePlaylist(player.getActivePlaylist())
    // })
  }, [user?.getUsername()])

  return (
    <>
      <HeaderWrapper>
        {/* <Link to="/" onClick={(event) => handleLogoClick(event)}> */}
        <Logo />
        {/* </Link> */}
        <SettingsContainer>
          <Action
            onClick={() => {
              setShowFavorites(!showFavorites)
              setShowSearch(false)
            }}
          >
            {!showFavorites && <Bookmark active={false} />}
            {showFavorites && <Close />}
          </Action>
          <Action
            onClick={() => {
              setShowSearch(!showSearch)
              setShowFavorites(false)
            }}
          >
            {!showSearch && <SearchIcon />}
            {showSearch && <Close />}
          </Action>
          <Action as={Link} to="/settings">
            <Settings />
          </Action>
          {onClose && (
            <Action onClick={onClose}>
              <Close />
            </Action>
          )}
        </SettingsContainer>
      </HeaderWrapper>
      <Search onSelect={() => setShowSearch(false)} show={showSearch} />
      <Favorites onSelect={() => setShowFavorites(false)} show={showFavorites} />
    </>
  )
}

export default Header
