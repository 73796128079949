import React, { useContext, useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { debounce } from 'lodash'
import EventEmitter from 'events'
import AnimatedLogo from '../../assets/animated_logo.gif'
import Logo from '../../assets/animated_logo-1.png'
import DESTINATION_QUERY from './destination.query'
import Image from '../../components/image/image'
import { DestinationEntityResponse, PlaylistEntity } from '../../generated/graphql'
import DestinationDetailBottomBar from '../../components/destinationDetailBottomBar/destinationDetailBottomBar'
import EventPlayer from '../../components/eventPlayer/eventPlayer'
import GlobalContextContext from '../../utils/globalContext'
import usePrevious from '../../utils/usePrevious'

const DestinationWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`

const EventPlayerContainer = styled.div`
  position: absolute;
  right: 2rem;
  bottom: 1rem;
`

const AnimatedPlayLogo = styled('img')`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  transform: translate(-50%, -100%);
  mix-blend-mode: multiply;
`

const PlaylistImage = styled(Image)`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
`

const DestinationPlaceholder = styled.div`
  background: ${(props) => props.theme.colors.antless1};
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${(props) => props.theme.breakpoints.md} {
    border-radius: 1.5rem;
  }
  img {
    width: 75px;
    height: auto;
  }
`

const Slider = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.9rem;
  // display: flex;
  // flex-direction: column;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  &::-webkit-scrollbar {
    display: none;
  }
  & > div {
    scroll-snap-align: start;
    width: 100%;
    height: 100%;
    overflow: hidden;
    & > div {
      width: 100%;
      height: 100%;
    }
  }
`

const Destination = ({
  playlistId,
  destinationId,
}: {
  playlistId?: number | undefined
  destinationId?: number | undefined
}) => {
  const id = destinationId
  const prev: { playlistId: number; destinationId: number } | undefined = usePrevious({
    destinationId,
    playlistId,
  }) as any
  // const params = new URLSearchParams(window.location.search)
  // const playlistId = params.get('playlist')
  const slider = useRef<HTMLDivElement>(null)
  const [selectedPlaylist, setSelectedPlaylist] = useState<PlaylistEntity>()
  const {
    loading,
    error,
    previousData,
    data: d,
  } = useQuery<{ destination: DestinationEntityResponse }>(DESTINATION_QUERY, { variables: { id } })
  const data = d || previousData
  const { playerStatus } = useContext(GlobalContextContext)
  useEffect(() => {
    if (!data) return
    if (
      playlistId &&
      data?.destination?.data?.attributes?.playlists?.data?.find(
        (playlist) => Number(playlist.id) === Number(playlistId)
      )
    ) {
      const pl = data?.destination?.data?.attributes?.playlists?.data?.find(
        (playlist) => Number(playlist.id) === Number(playlistId)
      )
      setSelectedPlaylist(pl)
    } else if (
      data.destination?.data?.attributes?.defaultPlaylist?.data &&
      data.destination.data.attributes.defaultPlaylist.data.id
    ) {
      const { playlists } = data.destination.data.attributes
      const defaultId = data.destination.data.attributes.defaultPlaylist.data.id
      if (!playlists) return
      setSelectedPlaylist(playlists.data.find((playlist: any) => playlist.id === defaultId))
    } else if (data.destination?.data?.attributes?.playlists) {
      const { playlists } = data.destination.data.attributes
      if (!playlists) return
      setSelectedPlaylist(playlists.data[Math.floor(playlists.data.length / 2)])
    }
  }, [playlistId, id, data])

  useEffect(() => {
    const index =
      data?.destination?.data?.attributes?.playlists?.data.findIndex(
        (p) => p.id === selectedPlaylist?.id
      ) || 0
    const sliderHeight = slider.current?.getBoundingClientRect().height || 0
    if (prev && prev.destinationId && prev.playlistId && prev.destinationId === destinationId) {
      slider.current?.scrollTo({ top: sliderHeight * index, behavior: 'smooth' })
    }

    // no listen to scrolling and change the playlist
    const handleSliderScroll = () => {
      const scrolledToIndex = Math.round((slider.current?.scrollTop || 0) / sliderHeight)
      setSelectedPlaylist(data?.destination?.data?.attributes?.playlists?.data[scrolledToIndex])
    }
    const debouncedHandler = debounce(handleSliderScroll, 800)
    slider.current?.addEventListener('scroll', debouncedHandler)
    return () => {
      slider.current?.removeEventListener('scroll', debouncedHandler)
    }
  }, [selectedPlaylist, destinationId])

  useEffect(() => {
    const index =
      data?.destination?.data?.attributes?.playlists?.data.findIndex(
        (p) => p.id === selectedPlaylist?.id
      ) || 0
    const sliderHeight = slider.current?.getBoundingClientRect().height || 0
    slider.current?.scrollTo({ top: sliderHeight * index, behavior: 'instant' as any })
  }, [destinationId])

  if (!destinationId || !data || !selectedPlaylist)
    return (
      <DestinationPlaceholder>
        <div>
          <img alt="Antless Logo" src={AnimatedLogo} />
        </div>
        <EventPlayerContainer>
          <EventPlayer />
        </EventPlayerContainer>
      </DestinationPlaceholder>
    )

  const onBackgroundClick = () => {
    ;((window as any).eventHandler as EventEmitter).emit('CLOSE_ALL')
  }
  // if (!selectedPlaylist) return <div />

  // const url = selectedPlaylist.attributes?.cover?.data?.attributes?.url
  // const alternativeText = selectedPlaylist.attributes?.cover?.data?.attributes?.alternativeText
  // if (!url || !alternativeText) return <div />
  // const { url, alternativeText } = selectedPlaylist.data?.attributes.coverImage.data.attributes

  return (
    <DestinationWrapper>
      <Slider onClick={onBackgroundClick} ref={slider}>
        {data?.destination?.data?.attributes?.playlists?.data.map((p) => (
          <div>
            <PlaylistImage
              src={p.attributes?.cover?.data?.attributes?.url as string}
              alt={p.attributes?.cover?.data?.attributes?.alternativeText as string}
            />
          </div>
        ))}
      </Slider>
      <AnimatedPlayLogo alt="Animated Logo" src={playerStatus?.isPlaying ? AnimatedLogo : Logo} />
      <DestinationDetailBottomBar
        playlist={selectedPlaylist}
        playlists={data.destination?.data?.attributes?.playlists?.data}
        setPlaylist={(playlist: PlaylistEntity) => setSelectedPlaylist(playlist)}
        destinationId={Number(id)}
        destinationTitle={data.destination.data?.attributes?.title || ''}
      />
    </DestinationWrapper>
  )
}

Destination.defaultProps = {
  playlistId: undefined,
  destinationId: undefined,
}

export default Destination
