import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import theme from '../../styles/theme'
import Logo from '../../assets/logo.png'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 1.5rem;
  align-items: center;
  background: ${theme.colors.antless1};
  img {
    width: 75px;
    height: auto;
  }
`

const AuthHeader = () => {
  const location = useLocation()
  const isEventScreen = location.pathname.includes('/register/event')
  return (
    <Wrapper>
      <div>
        <img alt="Antless Logo" src={Logo} />
        {isEventScreen && <div>Event</div>}
      </div>
    </Wrapper>
  )
}

export default AuthHeader
